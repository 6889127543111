'use strict';

export const date_format='YYYY-MMM-DD';
export const date_time_format='YYYY-MMM-DD HH:mm:ss'; //for display eg:2019-Jan-01 15:10:00
export const date_time_format_for_api='YYYY-MM-DD HH:mm:ss'; //for api eg:2019-01-01 15:10:00

//constants for all placeholders
export const urlPlaceholder = "ex. http://www.yourexample.com";
export const namePlaceholder = "ex. Name";
export const commentsPlaceholder = "Leave your comments here";
export const numberPlaceholder = "ex. 10";
export const maxNumberPlaceholder = "ex. 1000";
export const messagePlaceholder = "Add your message here...";
export const dropdownPlaceholder = "Select Option";
export const templateNamePlaceholder = "ex. Template Content";
export const templateSubjectPlaceholder = "ex. Template Subject";
export const emailPlaceholder = "ex. youremail@email.com";
export const searchPlaceholder = "Search";
export const surnamePlaceholder = "ex. Last Name";
export const postalcodePlaceholder = "ex. 400034";
export const phonePlaceholder = "ex. 9960123456";
export const questionNamePlaceholder = "ex. What is your Education?";
export const variableTextPlaceholder = "ex. Graduate";
export const detailsPlaceholder = "Write details here...";
export const activityPlaceholder = "Select Activity";
export const datePlaceholder = "ex. Select Date";
export const listPlaceholder = "ex. Sample List";
export const cityPlaceholder = "ex. Mumbai";