import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SigninComponent } from './signin/signin.component';
import { RouteGuard } from 'app/authGurad/route.guard';

const authRoutes: Routes = [
  { path: 'signin', component: SigninComponent, canActivate: [RouteGuard] },
];

@NgModule({
  imports: [
    RouterModule.forChild(authRoutes)
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
