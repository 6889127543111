import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Component({
	'selector':'base',
	'template':'',
	'styles':[]
})

export class BaseComponent implements OnDestroy{
	constructor(
  ) { }

	ngUnsubscribe = new Subject<void>();

	ngOnDestroy():void{
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}