import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { environment } from "environments/environment";

@Injectable()
export class AuthService {
  constructor(private http: HttpClient, private router: Router) { }

  getToken(): string {
    return localStorage.getItem('token');
  }
  logIn(obj): Observable<any> {
    const url = `${environment.apiEndpoint}auth/authenticate`;
    return this.http.post<User>(url, obj);
  }
  validateToken(obj): Observable<any> {
    const url = `${environment.apiEndpoint}account/validate-token-forgot-password`;
    return this.http.post<any>(url, obj);
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/']);
  }

}
