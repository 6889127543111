import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SigninComponent } from './signin/signin.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthService } from './store/auth.service';
import { AuthEffects } from './store/auth.effects';
import { reducers } from './store/states';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '../core/core.module';

@NgModule({
  declarations: [
    SigninComponent
  ],
  imports: [
    FormsModule,
    AuthRoutingModule,
    CommonModule,
    BrowserModule,
    CoreModule
  ],
  providers: [AuthService]
})
export class AuthModule {}
