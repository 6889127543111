import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ShareDataService } from '../../shared/share-data.service';
import { LocalstoreService } from '../../shared/localstore.service';
import { ToastrService } from "ngx-toastr";
// import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AuthService } from '../../auth/store/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  projects: any = [];
  panels: any = [];
  reports: any = [];
  action;
  modname = "Project";
  spinnerService: any;
  httpservice: any;
  pollLists: any;

  constructor(private router: Router,
    private _shareData: ShareDataService,
    private route: ActivatedRoute,
    private localStoreService: LocalstoreService,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit() {
      this.modname = this.router.url.split('/')[1];
  }

  logout() {
    this.authService.logout();
  }

}
