import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectAuthState } from '../store/states';
import { CommonService } from '../../shared/common.service';
import { Observable } from 'rxjs';
import { AuthService } from '../store/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LocalstoreService } from 'app/shared/localstore.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  getState: Observable<any>;
  ifError: boolean = false;
  ifSucc: boolean = false;
  errorMessage: string = '';
  successMessage: string = '';
  email;
  password;
  count;
  passwordPatern = {};
  emailPattern = {};

  constructor(
    private store: Store<AppState>,
    private cs: CommonService,
    private as: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private localstoreService:LocalstoreService,
  ) {
    this.getState = this.store.select(selectAuthState);
    // console.log(this.getState);
  }

  ngOnInit() {
    this.passwordPatern = this.cs.paternMessage('password');
    this.emailPattern   = this.cs.paternMessage('email');
  }

  onSubmit(formval, txtid, imgid): void {
    const payload = {
      email: formval.value.email,
      password: formval.value.password
    };
    this.cs.hideshowImge(txtid, imgid, 'a');
    this.as.logIn(payload).subscribe(res => {
      let response = res;
      if (response.success) {
        localStorage.setItem('token', response.data.token);
        this.localstoreService.removeLocal('user');
        this.localstoreService.setLocal('user', 'detail', response.data.userData)
        this.localstoreService.lastAction('last-action', 'login');
        this.router.navigateByUrl('dashboard');
      } else {
        this.toastr.error(response.message);
      }
      this.cs.hideshowImge(txtid, imgid, 'i');
    })
  }

}
