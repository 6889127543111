import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { LocalstoreService } from './localstore.service';
import { HttpService } from 'app/store/http/http.service';
import * as requrls from '../panel/store/requrl';
@Injectable({
  providedIn: 'root'
})
export class ShareDataService {

  constructor(private localstore:LocalstoreService,
              private httpservice: HttpService ) { }

  private _listners = new Subject<any>();
  _panelInfo = new BehaviorSubject<any>({});
  _panelStat = new BehaviorSubject<any>({});
  currentSelectedPanel = this._panelInfo.asObservable();
  currentSelectedPanelStat = this._panelStat.asObservable();

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  filter(filterBy: string) {
    this._listners.next(filterBy);
  }

  panelistStatus(id = null){
    const status = { 1 : 'Active' ,    2 : 'BadEmail' ,    3 : 'Deleted' ,    4 : 'Activation Pending' ,    5 : 'Sleeping' ,    6 : 'Banned' ,    7 : 'Unsubscribed' ,    8 : 'Spam' ,    9 : 'UTC' ,    10 : 'Inactive' };
    if(id && status[id]){
      return status[id];
    }
    return status;
  }

  // currentSelectedPanel1():Observable<any>{
  //   return this._panelInfo.asObservable();
  // }

  // currentSelectedPanelStat1():Observable<any>{
  //   return this._panelStat.asObservable();
  // }

  selectedPanel(panel:any){
    this._panelInfo.next(panel);
    this.localstore.setLocal('panel', 'detail', panel);
    let payload = {
      'panel_id': panel.id
    }
    this.httpservice.postData(payload, requrls.dashboardcount).pipe().subscribe(res => {
    let response = res;
     if (response.success) {
        response.data['panel_id'] = panel.id;
        this._panelStat.next(response.data);
      }
    })
  }

  panelStatus(id = null){
    const status = { 1 : 'Available' ,    2 : 'Unavailable' ,    3 : 'Invisible'};
    if(id && status[id]){
      return status[id];
    }
    return status;
  }
}
