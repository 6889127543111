import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
// import * as _ from 'lodash';
import * as moment from 'moment';
import * as global from './global';

declare var $: any;

@Injectable({
  providedIn: "root"
})
export class CommonService {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  hideshowImge(stimg, imgid, call) {
    if (call == "a") {
      document.getElementById(stimg).style.display = "none";
      document.getElementById(imgid).style.display = "inline-block";
    } else {
      document.getElementById(stimg).style.display = "inline-block";
      document.getElementById(imgid).style.display = "none";
    }
  }

  hideshowImgeFlex(data, loader, call) {
    if (call == 'a') {
      document.getElementById(data).style.display = 'none';
      document.getElementById(loader).style.display = 'inline-block';
    } else {
      document.getElementById(data).style.display = 'flex';
      document.getElementById(loader).style.display = 'none';
    }
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  formatDateMonthYear(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, year].join("-");
  }

  formatDateWithTime(date) {
    return moment(new Date(date)).format(global.date_time_format_for_api);
    //let d = moment(new Date(date)).format('YYYY-MM-DD H:m:ss'),
      //month = "" + (d.getMonth() + 1),
      //day = "" + d.getDate(),
      //year = d.getFullYear(),
      //mins = ((d.getMinutes().toString().length) < 2) ? "0" + d.getMinutes() : d.getMinutes(),
      //hour =
        //d.getHours().toString().length < 2 ? "0" + d.getHours() : d.getHours(),
      //time = hour + ":" + mins + ":00";
    //if (month.length < 2) month = "0" + month;
    //if (day.length < 2) day = "0" + day;

    //return [year, month, day].join("-") + " " + time;
  }

  locatePage(pages, action, Queryval=null) {
    if (action == "new") {
      if(Queryval) {
        this.router.navigate([pages.newPage], { queryParams: { action: "new",project_id:Queryval  } });  
      } else {
        this.router.navigate([pages.newPage], { queryParams: { action: "new" } });
      }
    } else {
      if(Queryval) {
        this.router.navigate([pages.editPage], {
          queryParams: { action: "edit", project_id:Queryval  }
        });
      }else {
        this.router.navigate([pages.editPage], {
          queryParams: { action: "edit" }
        });
      }
      
    }
  }

  ConvertString(value) {
    return parseInt(value);
  }

  back() {
    window.history.back();
  }

  copied(e) {
    if (e.isSuccess) {
      this.toastr.success('Copied to clipboard!');
    }
  }

  onCopyFailure() {
    this.toastr.error('cant Copied to clipboard!');
  }

  dropChkFn(list) {
    let dropArray = [];
    list.forEach(e => {
      if (e.value == '' || e.value == [] || e.value == null) {
        e.value = undefined;
      }
      if (e.value == undefined) {
        let element = document.getElementById(e.key);
        dropArray.push(e);
        element.classList.remove("d-none");
      } else {
        let element = document.getElementById(e.key);
        element.classList.add("d-none");
      }
    });
    return dropArray;
  }

  showError(errorArray, call) {
    for (const key in errorArray) {
      if (errorArray.hasOwnProperty(key)) {
        const element = errorArray[key];
        if(document.getElementById(key)){
          if (call == 'a') {
            document.getElementById(key).style.display = 'block';
            document.getElementById(key).classList.add("cust-error");
            document.getElementById(key).innerHTML = element;
          } else {
            document.getElementById(key).style.display = 'none';
          }
        }
      }
    }
  }

  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  afterreset() {
    document.getElementById('headname').click();
  }
  paternMessage(validator){
    switch(validator) { 
      case 'password': { 
        return  this.passwordPattern(); 
      } 
      case 'email': { 
        return this.emailPattern();  
      } 
      default: { 
         //statements; 
         break; 
      } 
   } 
  }
  panelistStatus(){
    return [
      { label: 'Active', value: '1' },
      { label: 'BadEmail', value: '2' },
      { label: 'Deleted', value: '3' },
      { label: 'Activation Pending', value: '4' },
      { label: 'Sleeping', value: '5' },
      { label: 'Banned', value: '6' },
      { label: 'Unsubscribed', value: '7' },
      { label: 'Spam', value: '8' },
      { label: 'UTC', value: '9' },
      { label: 'Inactive', value: '10' },
    ]
  }

  passwordPattern() {
    var pattern = "^\\S*$";
    return {'minlength' : 6 ,'maxlength' : 20 , 'pattern' : pattern , 'minErrorMsg' : "Password can be a mixture of letters, numbers or special characters and should be 6-20 character long" ,'spaceErrMsg' : "Password can be a mixture of letters, numbers or special characters and should be 6-20 character long",'conformPasswordErrorMsg':"New password and confirm new password should same","requiredErrorMsg" : "Required"};
  }

  emailPattern() {
    var pattern = "^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$";
    return { 'pattern' : pattern , "requiredErrorMsg" : "Required","validEmail" : "Please enter valid email"};
  }

  panelistStatusActivity(){
    return [
      { label: 'Active', value: '1' },
      { label: 'Activation Pending', value: '4' },
      { label: 'Sleeping', value: '5' },
      { label: 'Inactive', value: '10' },
    ]
  }

}
